@import "./../../styles/variables.scss";

.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $footer-height-mobile;
  box-sizing: border-box;
  background-color: $cherry;
  position: absolute;
  bottom: 0;

  @media(min-width: $tablet) {
    height: $footer-height-tablet;
    padding: 0 5.403rem;
  }

  @media(min-width: $tablet-horz) {
    height: $footer-height-tablet-horz;
    padding: 0 2.312rem;

  }

  @media(min-width: $desktop) {
    height: $footer-height-desktop;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100%;

    @media(min-width: $tablet) {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 36rem;
    }
    @media(min-width: $tablet-horz) {
      max-width: 92rem;
    }

    .logo {
      @media(min-width: $tablet) {
        flex: 1 0 33.33%;
      }
      @media(min-width: $tablet-horz) {
        flex: 1 0 10%;
      }

      a {
        display: block;
        width: 5.438rem;
        height: 4.125rem;
        margin: .875rem auto 0;

        @media(min-width: $tablet) {
          margin-top: 1.175rem;
        }
        @media(min-width: $tablet-horz) {
          width: 7.688rem;
          height: 5.813rem;
          margin-left: 0;
        }
        @media(min-width: $desktop) {
          width: 10.688rem;
          height: 8.063rem;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .LegalLink {
      // width: 18.889rem;
      margin: .984rem auto 0;

      @media(min-width: $tablet) {
        margin: 1.984rem auto  -1rem;
        flex: 1 0 66.66%;
      }
      @media(min-width: $tablet-horz) {
        flex: 1 0 50%;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        margin-left: 1rem;
        padding: 0;
        max-width: 48rem;

        li {
          flex: 0 1 50%;
          text-align: left;

          @media(min-width: $tablet-horz) {
            flex: 0 1 33.33%;
          }

          a {
            margin-left: 1rem;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.38;
            color: $white;
            text-transform: uppercase;
            text-decoration: dashed;

            @media(min-width: $desktop) {
              font-size: 1.375rem;
            }

            &:hover {
              color: $brown;
            }
          }
        }
      }
    }

    .Socials {
      margin: 3.5rem auto 1rem;

      @media(min-width: $tablet) {
        flex: 1 0 100%;
        margin: 2.5rem auto 1rem;
      }
      @media(min-width: $tablet) {
        flex: 1 0 45%;
      }

      a {
        margin-right: 1.72rem;

        &:last-child {
          margin-right: 0;
        }

        >svg {
          fill: $white;
        }

        &:hover {
          >svg {
            fill: $brown;
          }
        }
      }
    }
    .cookie-pref-bar {
      position: fixed;
      bottom: 5px;
    }
    .CookiesLink {
      font-weight: bold;
      text-transform: uppercase;

      &:hover {
        color: $brown;
      }
    }
  }
}