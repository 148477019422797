@import url("https://use.typekit.net/tjz4mfp.css");

/** Colors **/
$brown: #541712;
$amber: #f6a800;
$sienna: #b25619;
$cherry: #f1162b;
$black: #000;
$white: #fff;


/** Breakpoint **/
$mobile: 375px;
$tablet: 768px;
$tablet-horz: 1024px;
$desktop: 1920px;


/** Measurements **/
$header-height-mobile: 3.875rem;
$header-height-tablet: 6.188rem;
$header-height-tablet-horz: 6.188rem;
$header-height-desktop: 7.375rem;

$footer-height-mobile: 23.625rem;
$footer-height-tablet: 16.5rem;
$footer-height-tablet-horz: 11.375rem;
$footer-height-desktop: 15.625rem;

$page-height-mobile: calc(100% - ($header-height-mobile + $footer-height-mobile));
$page-height-tablet: calc(100% - ($header-height-tablet + $footer-height-tablet));
$page-height-tablet-horz: calc(100% - ($header-height-tablet-horz + $footer-height-tablet-horz));
$page-height-desktop: calc(100% - ($header-height-desktop + $footer-height-desktop));
