@import "./../../styles/variables.scss";

.RecipePage {

  .recipes {
    margin: 3rem auto 0;

    @media(min-width: $tablet){
      margin-top: 1re;
    }

    @media(min-width: $tablet-horz){
      margin: 6vw auto
    }

    @media(min-width: $desktop){

    }
  }
}