@import "./../../styles/variables.scss";

.MainPage {
  min-height: 100vh;
  position: relative;
  color: $white;
  background: url(./../../assets/template/glass-background-mobile.png), linear-gradient(to bottom, rgba(178, 86, 25, 0), $sienna);
  background-size: 100% auto, auto 18%;
  background-repeat: no-repeat, repeat-x;
  background-position: center top $header-height-mobile, center bottom $footer-height-mobile;

  .skip-link {
    
    background-color: $white;
    color: $black;
    font-weight: 700;
    font-size: larger;
    left: 0;
    top: 0;
    padding: 4px;
    position: absolute;
    transform: translateY(-100%);
    z-index: 1000;

    &:focus {
      transform: translateY(0%);
    }
    
  }

  main {
    padding-bottom: $footer-height-mobile;

    @media(min-width: $tablet) {
      padding-bottom: $footer-height-tablet;
    }
    @media(min-width: $tablet-horz) {
      padding-bottom: $footer-height-tablet-horz;
    }

    @media(min-width: $desktop) {
      padding-bottom: $footer-height-desktop;
    }
    
  }
  
  @media(min-width: $tablet) {
    // min-height: 980px;
    background: url(./../../assets/template/glass-background-tablet.png), linear-gradient(to bottom, rgba(178, 86, 25, 0), $sienna);
  background-size: 100% auto, auto 14%;
    background-repeat: no-repeat, repeat-x;
    background-position: center top $header-height-tablet, center bottom $footer-height-tablet;
  }

  @media(min-width: $tablet-horz) {
    // min-height: 86vw;
    background: url(./../../assets/template/glass-background-tablet-horz.png), linear-gradient(to bottom, rgba(178, 86, 25, 0), $sienna);
  background-size: 100% auto, auto 22%;
    background-repeat: no-repeat, repeat-x;
    background-position: center top calc($header-height-tablet-horz - .7vw), center bottom $footer-height-tablet-horz;
  }

  @media(min-width: $desktop) {
    // min-height: 1400px;
    background: url(./../../assets/template/glass-background-desktop.png), linear-gradient(to bottom, rgba(178, 86, 25, 0), $sienna);
    background-size: 100% auto, auto 20%;
      background-repeat: no-repeat, repeat-x;
    background-position: center top calc($header-height-desktop - 4vw), center bottom $footer-height-desktop;
  }
}