@import "./../../styles/variables.scss";

.Recipe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media(min-width: $tablet) {
    flex-direction: row;
    margin-bottom: 3rem;
  }

  @media(min-width: $tablet-horz) {
    gap: 2rem;
  }

  img{
    width: 20.625rem;
    height: auto;
    border: 3px solid $amber;
    border-radius: 25px;

    @media(min-width: $tablet) {
        width: 20.813rem;
    }

    @media(min-width: $tablet-horz) {
      width: 26rem;
    }

    @media(min-width: $desktop) {
      width: 48.25rem;
      border-radius: 40px;
      border: 5px solid $amber;
    }

    
  }

  .copy {
    width: 20.625rem;
    text-align: left;
    font-weight: 600;

    @media(min-width: $tablet) {

    }

    @media(min-width: $tablet-horz) {
      
    }

    @media(min-width: $desktop) {
      width: 48.25rem;
    }

    h2 {
      margin-top: .4rem;
      font-size: 2rem;
      font-weight: 900;
      font-family: "alternate-gothic-condensed-a";
      font-style: italic;
      font-weight: 900;
      color: $amber;

      @media(min-width: $tablet) {
        font-size: 2.313rem;
      }

      @media(min-width: $tablet-horz) {
        
      }

      @media(min-width: $desktop) {
        font-size: 3.375rem;
      }
    }

    h3, p {
      font-family: Arial;

      @media(min-width: $tablet) {

      }
  
      @media(min-width: $tablet-horz) {
        
      }
  
      @media(min-width: $desktop) {
        font-size: 1.625rem;
      }
    }

    h3{
      text-transform: uppercase;
      font-weight: 100;
    }
  }
}