.Icons {}

.Hamburger{

  path {
    fill:none;
    stroke:#fff;
    stroke-width:3px;
  
  }
} 