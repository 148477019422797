@import "./../../styles/variables.scss";

.AboutPage {
  padding: 5rem 1.438rem 0;

  @media(min-width: $tablet) {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: $desktop) {
      flex-direction: row;
      justify-content: center;
      align-items: start;
      gap: 5rem;
      margin-top: 15%;
    }

    .imgContainer {
      width: 20.625rem;



      @media(min-width: $tablet) {
        width: 42.5rem;
      }


      @media(min-width: $desktop) {
        width: 48.25rem;
        margin-top: 2rem;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: fill;
        border: 3px solid $amber;
        border-radius: 30px;

      @media(min-width: $tablet) {
        border-radius: 40px;
        border: 5px solid $amber;
      }


      @media(min-width: $desktop) {
        width: 48.25rem;
      }
      }
    }
    .copy{
      max-width: 35rem;

      @media(min-width: $tablet) {
        max-width: 60rem;
      }

      @media(min-width: $desktop) {
        width: 48.25rem;
      }
      h1, h2{
        font-size: 2.813rem;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 0;

        @media(min-width: $tablet) {
          font-size: 4rem;
        }

        @media(min-width: $desktop) {
          font-size: 7.5rem;
          text-align: left;
          margin: 1rem 0;
          line-height: 1;
        }
      }
      p {
        font-family: Arial;
        text-align: left;
        margin-top: 0;

        @media(min-width: $tablet) {
          font-size: 1.25rem;
          line-height: 1.4;
        }

        @media(min-width: $desktop) {
          font-size: 1.5rem;
        }
      }

      h3 {
        font-size: 2.25rem;
        font-weight: 900;
        font-family: "alternate-gothic-condensed-a";
        font-stretch: condensed;
        font-style: italic;
        color: $amber;

        &::after{
          content: "TM";
          font-size: xx-small;
        }

        @media(min-width: $tablet) {
          font-size: 2.438rem;
        }

        @media(min-width: $desktop) {
          font-size: 3.25rem;
          text-align: left;
        }
      }
    }
  }
}