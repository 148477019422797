@import "./../../styles/variables.scss";

.Header {
  display: flex;
  align-items: center;
  position: relative;
  height: $header-height-mobile;
  box-sizing: border-box;
  padding: 0.812rem 1.25rem;
  background-color: $brown;
  color: $white;

  @media(min-width: $tablet) {
    height: $header-height-tablet;
    padding: 2rem 2.25rem;
  }

  @media(min-width: $tablet-horz) {
    height: $header-height-tablet-horz;
  }

  @media(min-width: $desktop) {
    height: $header-height-desktop;
  }

  >.hamburger {
    width: 2.313rem;
    height: 2.25rem;
    border: none;
    background-color: transparent;
    // background-image: url(./../../assets/header/m-s-header-button-menu.svg);
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: center;
    cursor: pointer;

    svg>path{
      transition: transform .3s ease-in-out;
    }

    &:hover {
     svg>path{
      &[data-name="Line 3"]{
        transform: translate(0, 1px);
      }
      &[data-name="Line 1"]{
        transform: translate(0, 35px);
      }
     }
    }

    @media(min-width: $tablet) {

    }

    @media(min-width: $desktop) {
      display: none;
    }
  }

  >.logo {
    display: block;
    width: 5.563rem;
    height: 4.25rem;
    margin: 0.25rem auto 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    @media(min-width: $tablet) {
      width: 9.188rem;
      height: 7rem;
      margin-top: 0.875rem;
    }

    @media(min-width: $desktop) {
      width: 12.063rem;
      height: 9.125rem;
      margin-top: 0.938rem;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .linksContainer {
    display: flex;
    align-items: center;
    margin-left: auto;


    >.link {
      display: none;
      margin-right: 3.25rem;
      color: $white;
      font-size: 2.25rem;
      font-weight: 900;
      font-stretch: condensed;
      text-transform: uppercase;
      text-decoration: none;
      
      @media(min-width: $desktop) {
        display: block;
      }

      &:hover {
        color: $amber;
      }
    }

    .findMug {
      display: none;
      @media(min-width: $tablet) {
        display: flex;
        width: 12.375rem;
        height: 3.688rem;
        font-size: 1.625rem;
        font-size: 2.25rem;
      }
    }
  }
}