@import "./../../styles/variables.scss";

.Hero {
  display: block;
  position: relative;
  margin: 4.375rem auto 0;
  margin-top: 20%;
  width: 18.875rem;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  font-size: 4.125rem;
  font-weight: 900;
  line-height: 0.85;
  text-align: center;
  color: $white;
  text-transform: uppercase;
  text-align: center;
  
  span {
    font-size: 8rem;
    width: 100%;

    @-moz-document url-prefix() {
      line-height: 1;
    }

    @media(min-width: $tablet) {
      font-size: 8.75rem;
    }
    @media(min-width: $tablet-horz) {
      font-size: 12.989vw;
    }
    @media(min-width: $desktop) {
      font-size: 14.375rem;
    }
  }

  &.TM::after {
    content: "TM";
    // position: absolute;
    margin-right: -2.5rem;
    bottom: 0;
    font-size: 1rem;

    @media(min-width: $tablet-horz) {
      font-size: 1.563vw;
    }

    @media(min-width: $desktop) {
      font-size: 1.8rem;
    }
  }

  @media(min-width: $tablet) {
    width: 25.5rem;
    margin-top: 6.25rem;
    margin-top: 15%;
    font-size: 4.5rem;

    // line-height: 0.76;
  }

  @media(min-width: $tablet-horz) {
    width: 40.25rem;
    margin-top: 9vw;
    margin-top: 15%;
    font-size: 6.641vw;
  }

  @media(min-width: $desktop) {
    width: 48.5rem;
    margin-top: 8.75rem;
    margin-top: 15%;
    font-size: 7.5rem;
  }
}