body {
  margin: 0;
  font-family: "alternate-gothic-atf", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, button {
  font-family: "alternate-gothic-atf";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#consent_blackbar{
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 9999;
}