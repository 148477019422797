@import "./../../styles/variables.scss";

.MobileMenu {
  position: fixed;
  background-color: $brown;
  top: 0;
  bottom: 0;
  left: -200vw;
  right: 200vw;
  overflow: auto;
  z-index: 1000;
  transition: all 1s ease;

  &.open {
    left: 0;
    right: 0;
  }

  @media(min-width: $desktop) {
    display: none;
  }

  .header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    height: $header-height-mobile;
    padding: 0.812rem 1.25rem;

    @media(min-width: $tablet) {
      height: $header-height-tablet;
      padding: 2rem 2.25rem;
    }
  
    @media(min-width: $tablet-horz) {
      height: $header-height-tablet-horz;
    }
  
    @media(min-width: $desktop) {
      height: $header-height-desktop;
    }

    >.logo {
      display: block;
      width: 5.563rem;
      height: 4.25rem;
      margin: 0.25rem auto 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
  
      @media(min-width: $tablet) {
        width: 9.188rem;
        height: 7rem;
        margin-top: 0.875rem;
      }
  
      @media(min-width: $desktop) {
        width: 12.063rem;
        height: 9.125rem;
        margin-top: 0.938rem;
      }
  
      img {
        width: 100%;
        height: auto;
      }
    }
  
    .close {
      width: 2.25rem;
      height: 2.25rem;
      border: none;
      background: url(./../../assets/mobile-menu/m-s-main-button-close.svg) no-repeat;
      background-color: transparent;
      background-size: contain;
      cursor: pointer;
    }
    
  }

  nav {
    display: flex;
    flex-direction: column;
    
    >a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 11.75rem;
      border: 1px solid $black;
      border-top: 0px;
      font-size: 3.25rem;
      font-weight: 900;
      font-stretch: condensed;
      color: $white;
      text-decoration: none;
      text-transform: uppercase;
      background-position: bottom -80px right;
      transition: background-position .3s ease-in;

      @media(min-height: $tablet) {

      }

      &:first-child{
        border: 1px solid $black;
      }

      &:hover {
        background-color: $amber;
        color: $brown;

        &:first-child {
          background-image: url(./../../assets/mobile-menu/menu-image-mug-dog.png);
          background-repeat: no-repeat;
          background-position: bottom right;

        }
      }
    }

    h2 {
      font-size: 3.25rem;
    }
  }
}