@import "./../../styles/variables.scss";

.Socials {
  h2 {
    font-size: 2.25rem;
    font-weight: 900;
    margin: 0;
    margin: 4rem auto 1rem;
    font-stretch: condensed;
    text-transform: uppercase;
    color: $white;
  }

  .iconContainer {
    display: flex;
  }
}

main, nav {
  .iconContainer {
    justify-content: center;
    margin-bottom: 4rem;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.483rem;
      height: 3.483rem;
      border-radius: 50%;
      margin-right: .951rem;
      background-color: $amber;
      // transition: background-color .3s ease-in-out;

      &:hover {
        background-color: $white;
      }

      &:last-child {
        margin-right: 0;
      }

      svg {
        fill: $brown;
      }
    }
  }
}