@import "./../../styles/variables.scss";

.HomePage {
  // min-height: 170vw;
  // margin-bottom: $footer-height-mobile;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    @media(min-width: $tablet) {
      flex-direction: row;
      align-items: end;
      max-width: 52rem;
      margin: 1rem auto 0;
    }
    @media(min-width: $tablet-horz){
      max-width: 80vw;
    }
    @media(min-width: $desktop) {
      max-width: 92rem;
    }
  }

  .container a {
    display: block;
    opacity: 0;
    margin-bottom: 2.25rem;
    cursor: pointer;

    @media(min-width: $tablet) {
      margin-bottom: 0;
      flex: 1 0 33.33%;
    }

    &:last-child {
      margin-bottom: 0;

    }

    &.about {
      width: 16.375rem;
      height: 12.25rem;

      @media(min-width: $tablet-horz) {
        width: 25.586vw;
        height: 21.875vw;
      }

      @media(min-width: $desktop) {
        width: 29.938rem;
        height: 25.063rem;
      }
    }

    &.find {
      width: 11.938rem;
      height: 13.125rem;
      @media(min-width: $tablet-horz) {
        width: 18.652vw;
        height: 20.508vw;
      }

      @media(min-width: $desktop) {
        width: 21.75rem;
        height: 23.438rem;
      }
    }

    &.recipes {
      width: 9.875rem;
      height: 14rem;
      @media(min-width: $tablet-horz) {
        width: 25.195vw;
        height: 21.973vw;
      }

      @media(min-width: $desktop) {
        width: 17.688rem;
        height: 24.188rem
      }
    }

    img {
      width: auto;
      height: 100%;
    }
  }
}