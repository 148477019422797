@import "./../../styles/variables.scss";

.PageNotFound {

  h2:nth-child(2){
    margin-top: 10%;
    color: $amber;
    text-transform: uppercase;
  }
  .Button {
    display: flex;
    width: 210px;
    height: 2.688rem;
    font-size: 1.625rem;
    margin: 0 auto;
  }
}