@import "./../../styles/variables.scss";

.FindMugPage {
  padding: 2rem;

  .container {
    margin: 15% auto 0;
    box-sizing: border-box;
    border-radius: 30px;
    max-width: $tablet-horz;
    overflow: hidden;



    @media(min-width: $tablet-horz) {
      margin-top: 5%;
    }

    @media(min-width: $desktop) {
      margin-top: 5rem;
    }
  }
}