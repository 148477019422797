@import "./../../styles/variables.scss";

.Button, a.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $amber;
  color: #000;
  border-radius: 500px;
  border: 0;
  font-weight: 900;
  // padding: 0.312rem 1.5rem;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: $white;
  }
}